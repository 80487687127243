.page-heading {
  font-weight: 700;
  font-size: 20px;
}
.flex-row {
  flex-direction: row;
}
.flex-col {
  flex-direction: column !important;
}
.align-center {
  align-items: center;
}
.align-baseline {
  align-items: baseline;
}
.align-end {
  align-items: end;
}
.justify-center {
  justify-content: center;
}
.justify-space-between {
  justify-content: space-between;
}
.justify-space-evenly {
  justify-content: space-evenly;
}
.justify-flex-end {
  justify-content: flex-end;
}
.justify-flex-start {
  justify-content: flex-start;
}

.delete-btn:hover {
  color: red;
  border-color: red;
}

.ant-list-item svg {
  width: 15px;
  height: 15px;
  margin-right: 5px;
}
.ant-list-item div,
p {
  cursor: pointer;
}
.ant-list-item .edit-profile {
  font-size: 12px;
  color: #959595;
  padding-left: 25px;
  margin: 0;
}
.ant-list-item .edit-profile:hover {
  color: #0037a2;
}
.ant-list-item div:hover {
  color: #0037a2;
}
.input-file {
  border: 1px solid #b1b5c3;
  padding: 10px;
  border-radius: 7px;
}
.avatar-text-img {
  background-color: #0037a2 !important;
  color: #fff !important;
  font-size: 18px;
  font-weight: 400;
  text-transform: capitalize !important;
}

.avatar-text-img svg path {
  color: #fff !important;
}
.board-view-task .ant-card-body {
  padding: 0;
  border: 1px solid transparent;
}
.board-view-task p {
  margin: 0 10px;
  padding: 5px 0;
  font-size: 13px;
  font-weight: 600;
  /* color: #6d6e6f; */
}
.board-view-task h5 {
  margin: 0;
  font-size: 11px;
  font-weight: 500;
  color: #707070;
}
.board-view-task .ant-btn {
  padding-right: 0;
}
.board-view-task:hover {
  border: 1px solid #dedede;
  border-radius: 12px;
  /* background-color: #f5f5f5; */
  transition: all 0.5s ease;
  transform: scale(1.05);
}
.board-view-task .ant-card-body {
  padding: 3px 7px;
}
.dropped-container {
  box-shadow: none !important;
  background-color: #fafafa;
}

.task-drawer-container .ant-btn > .anticon + span,
.ant-btn > span + .anticon {
  margin: 0;
}
.task-drawer-container .ant-form .task-header-section textarea {
  border: 1px solid #fff;
  font-size: 24px;
  padding: 0 2px;
  color: #000;
}
.task-drawer-container .ant-form .task-header-section h5 {
  font-size: 14px;
  color: #757575;
  margin: 0 10px;
}
.task-drawer-container .ant-form .task-header-section p {
  margin: 0;
  font-size: 12px !important;
  color: #7f7f7f;
}
.task-drawer-container .ant-form .task-drawer-label {
  font-size: 14px;
  color: #6d6e6f;
  text-align: initial;
  width: 100px;
}

.task-drawer-container .ant-form .task-drawer-status .ant-select-selector {
  border: 1px solid #fff;
  height: 35px;
  padding: 0;
}
.task-drawer-container .ant-form .ant-select-selector:focus {
  border: none;
  box-shadow: none;
}

.task-drawer-container .ant-form .task-drawer-assignee .ant-select-selector {
  border: 1px solid #fff;
  /* height: 40px; */
}
.task-drawer-container
  .ant-form
  .task-drawer-assignee
  .ant-select-single:not(.ant-select-customize-input)
  .ant-select-selector {
  height: 40px;
}
.task-drawer-container
  .ant-form
  .task-drawer-assignee
  .ant-select-selection-item {
  display: flex;
  align-items: center;
  height: 35px;
  border-radius: 10px;
}

.task-drawer-container .ant-form .task-drawer-description textarea {
  border: 1px solid #fff;
  font-size: 14px;
  padding: 0 2px;
  height: 150px !important;
  color: #000;
  font-size: 14px;
}
.task-drawer-container .ant-form textarea:hover {
  border: 1px solid gray;
  transition: all 0.5s ease;
}
.task-drawer-container .ant-form-item-control-input-content {
  text-align: initial;
}

.task-drawer-container .ant-picker {
  width: fit-content;
  height: 35px;
  padding: 5px;
  border: none;
}

.task-drawer-container .ant-input-disabled {
  background: transparent !important;
}

.task-drawer-container .task-start-date .ant-picker-input > input {
  font-weight: 400;
  color: #0d7f56;
  font-size: 14px;
}
.task-drawer-container .task-start-date .anticon svg {
  color: #0d7f56;
}
.task-drawer-container .task-end-date .anticon svg {
  color: #c92f54;
}
.task-drawer-container .task-end-date .ant-picker-input > input {
  font-weight: 400;
  color: #c92f54;
  font-size: 14px;
}

.create-task-modal .ant-modal-body {
  max-height: 500px;
  overflow-y: auto;
}

.project-detail-container .ant-menu-title-content {
  font-weight: 500 !important;
}

.project-detail-container .tabs-container .ant-select-arrow {
  padding-bottom: 10px;
}

.project-detail-container .tabs-container .ant-select-clear {
  position: absolute;
  top: 15px;
  right: 10px;
}

.project-activity {
  width: 50%;
  border: 1px solid #f0f0f0;
  border-radius: 12px;
}
.project-activity .comment-box p {
  cursor: default;
}
.project-activity h5 {
  font-size: 20px;
  font-weight: 500 !important;
  margin: 0;
  padding: 4px 20px;
}

.project-activity .ant-card {
  background-color: #fafafa;
  height: 350px;
  overflow-y: auto;
}
.project-activity .ant-card-body {
  padding: 0;
}
.project-activity .project-activity-info .ant-card-body {
  padding: 4px 15px;
}

.quillEditor-container {
  background-color: #fff;
  /* width: 100%; */
  height: 120px;
  margin: 22px;
  border: 1px solid darkgray;
  border-radius: 8px;
}

.quillEditor-container .ql-editor {
  padding: 2px 15px;
}
.quillEditor-container .ql-tooltip {
  left: 140px !important;
  top: 0 !important;
}

.task-status .ant-col-lg-6 {
  max-width: 24%;
}
.list-view-table .ant-table-tbody svg {
  width: 18px;
  height: 18px;
}
.list-view-table .ant-table-tbody tr > td {
  font-weight: 600;
}
.list-view-table .ant-table-tbody .task-row td {
  font-size: 13px;
  /* color: #767676; */
  color: #000;
  font-weight: 500 !important;
}
.list-view-table .ant-table-tbody tr > td:first-child {
  /* display: flex; */
}
.task-row td:first-child {
  padding: 10px 15px;
}

.views-tab-container ul {
  border: 1px solid #bbbbbb;
  background-color: #efefef !important;
  border-radius: 4px;
  height: 40px;
}
.views-tab-container li {
  height: 40px;
  line-height: 40px;
}
.project-detail-container .views-tab-container li span {
  font-weight: 700 !important;
}

.views-tab-container .ant-menu-item-selected {
  background-color: #ffffff;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
}

.p-description {
  font-size: 13px;
  color: #737373;
  font-weight: 400;
}

.admin-container .ant-select-selector {
  border: none !important;
  background: transparent !important;
}

.admin-container .ant-select-arrow svg {
  width: 12px;
  height: 12px;
}

.overview-container {
  margin: 20px;
}
.overview-container .comment-box .audit-box-p {
  max-width: 490px;
}
.overview-container textarea {
  min-height: 150px;
  /* border: none;
    padding-left: 2px;
    margin-bottom: 10; */
}
/* .overview-container textarea:hover {
    border: 1px solid #000;
    transition: all .5s ease;
} */
.overview-container .member-container {
  width: 100%;
  cursor: pointer;
  padding: 10px;
  border-radius: 6px;
}
.overview-container .overview-del-btn {
  visibility: hidden;
  color: red;
}
.overview-container .member-container:hover {
  background-color: #f9f8f8;
  .overview-del-btn {
    visibility: visible;
  }
}
.overview-member h5 {
  font-size: 14px;
  font-weight: 500;
  text-transform: capitalize;
}
.overview-member p {
  color: #6d6e6f;
  font-size: 12px;
  font-weight: 400;
}

.ant-popover-content .ant-popover-inner .ant-popover-inner-content {
  padding: 0 !important;
}
.ant-popover-content .ant-popover-inner {
  border-radius: 8px !important;
}
.ant-popover-content
  .ant-popover-inner
  .ant-popover-inner-content
  .popup-details {
  padding: 10px 15px 14px;
}
.ant-popover-content .ant-popover-inner .ant-popover-inner-content h5 {
  font-size: 22px;
  margin: 0 0 5px;
  font-weight: 600;
  text-transform: capitalize;
}
.ant-popover-content .ant-popover-inner .ant-popover-inner-content img {
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  width: 220px;
  height: 180px;
  object-fit: cover;
}
.ant-popover-content
  .ant-popover-inner
  .ant-popover-inner-content
  .name-letter-container {
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  width: 220px;
  height: 180px;
  font-size: 70px;
  color: #fff;
  text-transform: capitalize;
}
.ant-popover-content .ant-popover-inner .ant-popover-inner-content p {
  font-size: 12px;
  margin: 3px 5px;
  color: #6d6e6f;
}
.ant-popover-content .ant-popover-inner .ant-popover-inner-content svg path {
  color: #6d6e6f;
}
.ant-popover-content .ant-popover-inner .ant-popover-inner-content .ant-btn {
  height: 28px;
  line-height: 28px;
  width: 100%;
  margin: 8px 0 0px;
}

.profile-container .ant-col {
  padding: 0 !important;
}
.profile-container .ant-card {
  border: 1px solid #eeeeee;
  /* min-height:300px !important; */
  box-shadow: none !important;
}

.secondary-highlight {
  text-align: center;
  margin: 0 20px;
  background-color: #4ecbc4;
  color: #fff !important;
  max-width: 100px;
  padding: 2px 5px;
  border-radius: 10px;
  font-size: 12px !important;
}
.primary-highlight {
  text-align: center;
  margin: 0 20px;
  background-color: #8d84e8;
  color: #fff !important;
  max-width: 100px;
  padding: 2px 5px;
  border-radius: 10px;
  font-size: 12px !important;
}
.tertiary-highlight {
  text-align: center;
  margin: 0 20px;
  background-color: #4878bc;
  color: #fff !important;
  max-width: 100px;
  padding: 2px 5px;
  border-radius: 10px;
  font-size: 12px !important;
}
.todo-highlight {
  text-align: center;
  background-color: #f06bac;
  color: #fff !important;
  max-width: 100px;
  padding: 2px 5px;
  border-radius: 10px;
  font-size: 12px !important;
}
.todo-bg-highlight {
  color: #f06bac;
  min-width: 80px;
  background-color: rgba(240, 107, 172, 0.2);
  font-size: 12px;
  padding: 2px 5px;
  border-radius: 8px;
}
.progress-highlight {
  text-align: center;
  background-color: #0096ff;
  color: #fff !important;
  max-width: 100px;
  padding: 2px 5px;
  border-radius: 10px;
  font-size: 12px !important;
}
.progress-bg-highlight {
  color: #0096ff;
  min-width: 80px;
  background-color: rgba(0, 150, 255, 0.2);
  font-size: 12px;
  padding: 2px 5px;
  border-radius: 8px;
}
.done-highlight {
  text-align: center;
  background-color: #4bbaa3;
  color: #fff !important;
  max-width: 100px;
  padding: 2px 5px;
  border-radius: 10px;
  font-size: 12px !important;
}
.done-bg-highlight {
  color: #4bbaa3;
  min-width: 80px;
  background-color: rgba(75, 186, 163, 0.2);
  font-size: 12px;
  padding: 2px 5px;
  border-radius: 8px;
}
.hold-highlight {
  text-align: center;
  background-color: #ff5733;
  color: #fff !important;
  max-width: 100px;
  padding: 2px 5px;
  border-radius: 10px;
  font-size: 12px !important;
}
.closed-highlight {
  text-align: center;
  background-color: #f4c827;
  color: #fff !important;
  max-width: 100px;
  padding: 2px 5px;
  border-radius: 10px;
  font-size: 12px !important;
}

.hold-bg-highlight {
  color: #ff5733;
  min-width: 80px;
  background-color: rgba(255, 87, 51, 0.2);
  font-size: 12px;
  padding: 2px 5px;
  border-radius: 8px;
}

.priority-low {
  rotate: 270deg;
  margin: 5px;
  color: #003255;
}
.priority-medium {
  rotate: 90deg;
  margin: 5px;
  color: #ffb500;
}
.priority-high {
  rotate: 90deg;
  margin: 5px;
  color: red;
}
.notification-container {
  background-color: #f9f9f9;
  padding: 8px;
  border-radius: 8px;
  margin-bottom: 10px;
}
.unseen-notification {
  color: #000 !important;
}
.unseen-notification-bg {
  background-color: aliceblue !important;
}
.ant-popover-content .notification-name h5 {
  font-size: 14px !important;
  font-weight: 700 !important;
  margin: 0 5px !important;
}
.ant-popover-content .notification-container p {
  font-size: 15px !important;
  width: 310px;
}
.ant-popover-content .notification-container .more-options {
  cursor: "pointer";
  height: "fit-content";
  rotate: 90deg;
  padding: 2px;
  border-radius: 50%;
  font-size: 22px !important;
  transition: all 0.5s ease;
}

.ant-popover-content .notification-container .more-options:hover {
  background-color: #0000001f;
}
.ant-popover-content .notification-container .notification-time p {
  font-size: 10px !important;
  font-weight: 400;
  margin: 0;
  margin-right: 10px;
  color: #000;
  width: fit-content;
}
.ant-popover-content .notification-container img {
  width: 45px !important;
  height: 45px !important;
}
.ant-popover-content .view-all-notifications {
  position: absolute;
  bottom: 0;
  padding: 10px;
  width: calc(100% - 30px);
  background-color: #fff;
}

.noti-actions {
  padding: 5px 0;
}
.noti-actions span {
  margin-right: 5px;
}
.noti-actions p {
  width: 130px;
  padding: 10px 15px;
  margin: 0 !important;
  color: #000 !important;
}
.noti-actions p:hover {
  background-color: #f5f5f5;
}

.notification-actions-popup {
  left: calc(100% - 204px) !important;
  width: 130px;
}

.my-notifications-container .notification-section {
  background-color: #fafafa;
  padding: 10px;
  margin-bottom: 15px;
  border-radius: 8px;
}

.notification-section p {
  margin: 0 !important;
  font-size: 16px;
  color: #000;
  width: calc(100% - 130px);
}
.my-noti-actions {
  width: 50px;
}
.my-noti-actions span {
  font-size: 20px;
}

.delete-popover .ant-popover-inner {
  padding: 20px !important;
}
.delete-popover button {
  width: 70px !important;
}
.delete-popover .ant-popover-buttons {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.delete-popover .ant-popover-buttons .ant-btn-primary {
  background-color: #0037a2;
}

.ant-select-item-option-content {
  color: #818181;
  font-weight: 500;
}

.views-container {
  position: absolute;
  top: 60px;
  left: -35px;
  z-index: 9;
  background-color: #fff;
  border: 1px solid #e5e5e5;
  border-radius: 5px;
  box-shadow: 0 7px 30px rgb(0 0 0 / 20%);
  min-width: 160px;
  max-height: 150px;
  overflow-y: auto;
}

.views-container p {
  margin: 0 !important;
  padding: 10px;
  border-bottom: 1px solid #e6e6e6;
  width: 140px;
}
.views-container p:hover {
  background-color: #e6e6e6;
}
.views-container svg path {
  color: red !important;
}
.search-modal .ant-modal-header {
  /* padding: 16px 10px; */
}
.search-modal .ant-modal-title {
  text-align: center;
}
.search-modal .ant-input-prefix .anticon-search {
  color: #959595;
}
.search-modal .ant-modal-body {
  max-height: 400px;
  overflow-y: auto;
}
.search-modal .ant-input-affix-wrapper {
  /* border: 1px solid lightgrey; */
  border-radius: 22px;
  padding: 0 10px;
  /* width: 400px; */
}
.search-container h5 {
  font-size: 12px;
}
.search-container p {
  font-weight: 400;
  margin: 0;
  color: #000000;
}
.search-container .filter-tags {
  margin-bottom: 20px;
}
.search-container .filter-item {
  margin-right: 15px;
  cursor: pointer;
  border: 1px solid #e2e2e2;
  color: #6e6e6e;
  /* color: #1e56ca; */
  /* background-color: #eff8ff; */
  padding: 2px 10px;
  min-width: 72px;
  text-align: center;
  border-radius: 12px;
  font-size: 12px;
}
.search-container .filter-item:hover {
  background-color: #f8f8f8;
  border-color: #d5d5d5;
  /* background-color: #1e56ca; */
  /* color: #fff; */
}

.searched-item {
  padding: 6px;
  cursor: pointer;
  font-weight: 500;
  border-radius: 5px;
}
.searched-item:hover {
  background-color: #fafafa;
}

.search-field {
  border-radius: 20px;
  width: 300px;
  height: 40px;
}
.search-field input {
  height: 30px;
}

.asset-border {
  /* background: linear-gradient(to bottom right, #fd84b5, #8885fe); */
  background: #8885fe;
  padding: 1px;
  border-radius: 11px;
  margin: 20px 0;
}
.asset-item-container {
  padding: 10px;
  /* background-color: #fafafa; */
  background-color: #fff;
  border-radius: 10px;
  border: 1px solid #8885fe;
  font-size: 14px;
  font-weight: 500;
  margin: 20px 0;
  color: #000;
  /* box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px; */
}

.asset-info-select .ant-select-selector {
  border: none !important;
  background: transparent !important;
}
.asset-info-select .ant-select-arrow svg {
  width: 12px;
}
.asset-info-select .ant-select-clear {
  display: none;
}

.asset-item-container .assets-more-icon {
  rotate: 90deg;
  cursor: pointer;
  font-size: 20px;
  border-radius: 12px;
  padding: 1px;
  transition: ease 0.5s;
}
.asset-item-container .assets-more-icon:hover {
  background-color: #deeaff;
}

.assets-info-modal .ant-modal-title {
  font-weight: 700;
}
.assets-history-modal .ant-modal-body {
  background-color: #f8f8f8;
  padding: 10px 0 !important;
}

.asset-history-body {
  height: 400px;
  padding: 0 10px;
  overflow-y: auto;
}

.assign-highlight-btn {
  color: #4bbaa3 !important;
  width: fit-content;
  background-color: rgba(75, 186, 163, 0.2) !important;
  font-size: 12px;
  padding: 6px 10px;
  border: none !important;
  box-shadow: none !important;
}
.add-highlight-btn {
  color: #0096ff;
  width: fit-content;
  background-color: rgba(0, 150, 255, 0.2) !important;
  font-size: 12px;
  padding: 6px 10px;
  border: none !important;
  box-shadow: none !important;
}
.edit-highlight-btn {
  color: #ff0e65 !important;
  width: fit-content;
  background-color: rgb(247 61 132 / 20%) !important;
  font-size: 12px;
  padding: 6px 10px;
  border: none !important;
  box-shadow: none !important;
}
.asset-item-container .assets-column {
  display: flex;
  align-items: center;
}
.asset-item-container p {
  margin: 0;
}
.asset-item-container .asset-detail-card {
  background-color: #f4f8ff;
  border: none;
  border-radius: 8px !important;
  margin: 20px 0;
  max-height: 200px;
  overflow-y: auto;
  box-shadow: none !important;
}
.asset-property-card {
  background-color: #f4f8ff;
  border: none;
  border-radius: 8px !important;
  margin: 20px 0;
  padding: 20px 0;
  font-weight: 600;
}
.asset-history-property-card {
  background-color: #f4f8ff;
  border: none;
  border-radius: 8px !important;
  margin: 10px 0;
  padding: 10px 20px;
  font-weight: 600;
}
.asset-item-container .asset-detail-card p {
  font-weight: 500;
}
.asset-item-container .view-details p {
  font-weight: 500;
  width: fit-content;
  color: #185ba3;
  border: 2px solid #185ba3;
  width: fit-content;
  padding: 3px 8px;
  border-radius: 10px;
  transition: ease 0.5s;
}
.asset-item-container .view-details p:hover {
  background-color: #185ba3;
  color: #fff;
}
.category-list-container {
  width: 200px;
}
.category-list-container p {
  padding: 10px;
  width: 90%;
  margin: 0 !important;
  margin-top: 5px !important;
  text-transform: capitalize;
}
.category-list-container p:hover {
  background-color: #f7f7f7;
}
.category-list-container .add-category-btn {
  text-align: center;
}
.category-list-container .add-category-btn p {
  color: #02349c !important;
  font-weight: 500 !important;
}
.category-list-container svg path {
  color: #28cb28 !important;
}
.category-list-container .delete-icon svg path {
  color: red !important;
}

.form-item-horizontal .ant-form-item-row {
  flex-direction: row !important;
  align-items: center;
}
.form-item-horizontal .ant-form-item-control {
  width: fit-content !important;
  margin-left: 12px;
}

.asset-assign-modal {
  width: fit-content !important;
}
.asset-assign-modal .ant-select-selection-item {
  height: auto !important;
}
.asset-assign-modal .ant-modal-body {
  padding: 25px !important;
}
.asset-assign-modal p {
  margin-bottom: 0 !important;
}

.add-asset-item-modal .ant-select .ant-select-clear {
  display: none;
}

.add-asset-item-modal .ant-select-selector {
  padding: 2px 5px !important;
}

.assigned-assets-table .ant-table-tbody .asset-info svg {
  width: 15px;
  height: 15px;
}
.properties-popover .ant-popover-title {
  padding: 5px 12px 4px;
}
.project-task-cards .ant-card-body {
  padding: 10px 20px;
}

.comment-reaction-container .user-reaction-container {
  margin-left: 10px;
  padding: 0px 8px;
  border: 1px solid #ababab;
  font-size: 11px;
  border-radius: 16px;
}

.comment-reaction-container .comment-reaction-user img {
  scale: 1.1;
}

.comment-reaction-container .comment-reaction-user img:hover {
  transform: scale(1.3);
}

.comment-reaction-popover p img {
  scale: 1.1;
  margin: 0 5px;
}
.comment-reaction-popover p:hover {
  /* transition: all 0.2s ease; */
  transform: scale(1.05);
}
.comment-reaction-tooltip .ant-tooltip-inner {
  background-color: #000 !important;
}
.comment-reaction-tooltip .ant-tooltip-arrow {
  color: #000 !important;
}
